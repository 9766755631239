
window.onload = function () {
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    const form = document.getElementById('contact-form');

    form.addEventListener('submit', async (event) => {

        event.preventDefault();

        const Button = select(".Contact-Card button[type=submit]");

        select(".btn-text").style.display = "none";
        Button.disabled = true;

        const Loading = select(".Contact-Card .loading")
        Loading.style.display = "block";

        const name = form.name.value;
        const email = form.email.value;
        const message = form.message.value;
        const accountId = '06204009-6ecb-4bc4-9a8a-b9eb4a4c4b13';
        const production = true;
        const custom = 'none';

        try {
            const response = await fetch(`https://api.coalstudios.net/api/send-email`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, message, accountId, production, custom}),
        });

        console.log(response);

        const Data = await response.json();

        console.log(Data);


        Button.style.display = "none";


        if (response.ok && Data.success) {
            select(".Contact-Card .sent-message",true)[0].style.display = "block";
        } else {
            const Error = select(".Contact-Card .error-message",true)[0];

            if (Data.error.response != null) {
            Error.textContent = Data.error.response;
            } else {
            Error.textContent = "An error has occured"
            }

            Error.style.display = "block";
        }
        } catch(e){

            console.log(e);


            Button.style.display = "none";

            const Error = select(".Contact-Card .error-message",true)[0];
            Error.textContent = "An error has occured"
            Error.style.display = "block";

        }
    });
  };

