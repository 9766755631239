

<template>

    <section id="Hero" class="hero">

      <div class="row hero-background"> 
        <div style="background-image: url(assets/img/background/1.png);" class="hero-background-img"></div>
        <div class="overlay"></div>
            <div class="justify-content-center d-flex" style="z-index: 2;line-height: 1;">
              <div class="temp-container d-flex text-center" style="flex-direction: column;">
              

                <p class="hero-text">Liberating Leaders to Multiply Impact</p>
                <div class="d-flex justify-content-center hero-text-2" style="margin-left:70px">
                  <p class="hero-text-sm">Through</p>
                  <div class="animate-ovr">
                    <div class="animate-div">
                      <p>&nbsp;</p>
                    <p>Authenticity</p>
                    <p>Collaboration</p>
                    <p>Intrepidity</p>
                    <p>Transformation</p>
                  </div>
                  </div>
                </div>
                
                <p class="hero-subtext">Empowering Organizations with Expertise</p>
                <div class="d-flex justify-content-center">
                  <button class="btn-1 to-consultation">Get Started</button>
                  <div class="space"></div>
                  <button class="btn-2 to-about" data-to="#About">Learn More</button>
                </div>
                
              </div>
             
            
                     
            </div>
  
        </div>

    </section>

    <section id="About" class="about">
      <div class="d-flex justify-content-center container-1">
        <div class="text-center">
          <p class="text-1">intrepidity <t class="text-1-sm">[ in-tr<t style="font-style:italic">uh</t>-pid-i-tee ]</t></p>
          <p class="text-2">noun</p>
          <p class="text-3 text-start">1. the fact or quality of being resolutely <br/> &nbsp;&nbsp;&nbsp; fearless or dauntless; <strong style="color:#B58744">bold</strong> courage:</p>
          <div style="margin-top:20px;">
            <img src="assets/img/line-1.svg" style="width: 400px;"/>
          </div>
        </div>  
      </div>

      <div class="d-flex justify-content-center container-2">
        <div class="col-6 image-container-ovr d-flex justify-content-end" style="padding-right:150px;">      
          <div class="image-container">
            <img src="assets/img/about-1.jpg"/>
          </div>
        </div>  

        <div class="col-md-6 col-sm-12 d-flex justify-content-start container-3">      
          <div class="text-start text-container">
            <p class="text-1 text-center">Our Focus is <span style="font-weight:600;">People</span></p>
            <p class="text-2">Coffee lovers know what it feels like to have that perfect, bold cup in 
              hand — made even better when sitting across from a trusted friend.
              <br/><br/>
              At Bold Cup, we value having authentic, courageous conversations about what matters most to you 
               and your business — and we do that from a genuine place of collaboration and trust. We approach every 
               relationship with an insane curiosity — YOU are the expert of your business, the expert on building, 
               marketing and selling your products and services.
              <br/><br/>
              We bring our passion for the people behind your products and services, together with our suite of 21st 
              Century leadership development tools that create measurable, scalable and sustainable strategies for success!
              <br/><br/>
              Our consulting spans all kinds of industries — because it’s people focused.
              </p>
        <div class=" text-center">
          <button class="btn-3 to-consultation">Contact Us &nbsp; <i class="fa-solid fa-arrow-right"></i></button>
        </div>
          </div>
        </div>  
      </div>




    </section>


    <section id="Process" class="process">
      <div class="row process-background"> 
        <div style="background-image: url(assets/img/background/3.png);" class="process-background-img"></div>
        <div class="overlay"></div>

        <div class="content">
          <div class="d-flex justify-content-center">

            <div class="container-1 ">
              <p class="text-1">The Process</p>
              <div class="d-flex justify-content-center">
                  <p class="text-2">The E’s of transforming your</p>
                  <div class="animate-ovr">
                    <div class="animate-div">
                      <p>&nbsp;</p>
                    <p>Leadership</p>
                    <p>Teams</p>
                    <p>Workplace Culture</p>
                  </div>
                  </div>
                </div>
             
            </div>

            </div>

            <div class="d-flex justify-content-center" style="margin-top:100px;">

              <div class="container-2" id="expand-card">
                <div class="card">
                  <div class="d-flex justify-content-center">
                    <div style="background-image: url('assets/img/E-1.jpg');" class="img-container">
                    </div>
                    <div class="text-container">
                      <p class="text-1">Engage</p>
                      <p class="text-2" style="margin-top:15px;">in a multi-level culture audit 
                        to determine your current reality.  
                        <br/>
                        <a href="#" class="read-more">Read More<br/><br/>  </a> <!-- Add Read More link -->                    
                        <br/>    
                        We are committed to measuring your transformation from your starting 
                        baseline, through your growth process towards achieving your stated 
                        mission. </p>

                    </div>

                  </div>     
              </div>

            </div>


            </div>

            <div class="d-flex justify-content-center" id="trigger-process-animation">
              <div class="container-2">
                <div class="card" style="transition-delay: 0.2s">
                  <div class="d-flex justify-content-center">
                    <div style="background-image: url('assets/img/E-2.jpg');" class="img-container">
                    </div>
                    <div class="text-container">
                      <p class="text-1">Establish</p>
                      <p class="text-2">effective strategies, using proven tools to 
                        both <span>set</span> and <span>attain</span> your preferred 
                        future.
                        <br/>
                        <a href="#" class="read-more">Read More<br/> <br/> </a> <!-- Add Read More link -->
                        <br/> 
                        We believe your leadership health, team efficiency and employee 
                        engagement to be scalable assets. Our tools provide company-wide 
                        access to strategic learnings – with results!

                      
                      </p>
                    </div>
                  </div>     
              </div>

            </div>  
            </div>

            <div class="d-flex justify-content-center">
              <div class="container-2">
                <div class="card" style="transition-delay: 0.4s">
                  <div class="d-flex justify-content-center">
                    <div style="background-image: url('assets/img/background/5.png');" class="img-container">
                    </div>
                    <div class="text-container">
                      <p class="text-1">Embrace</p>
                      <p class="text-2">a <span>BOLD</span> collaboration process with our 
                        consultants to ensure results.
                        <br/>
                        <a href="#" class="read-more">Read More<br/> <br/> </a> <!-- Add Read More link -->
                        <br/>
                      We champion transformation over information. Our collaborative 
                      coaching approach supports sustainable growth throughout your 
                      team or entire organization.  
                      </p>
                    </div>
                  </div>     
              </div>

            </div>
            
           
            </div>

            <div class="d-flex justify-content-center">
              <div class="container-2">
                <div class="card" style="transition-delay: 0.6s">
                  <div class="d-flex justify-content-center">
                    <div style="background-image: url('assets/img/E-4.jpg');" class="img-container">
                    </div>
                    <div class="text-container">
                      <p class="text-1">Embolden</p>
                      <p class="text-2">your re-engaged, empowered, and transformed 
                        workforce to get things done!
                        <br/>
                        <a href="#" class="read-more">Read More<br/> <br/> </a> <!-- Add Read More link -->

                        <br/>
                        With a new common language, a suite of scalable tools, together 
                        with our customized coaching, your teams are empowered for 
                        sustained performance! 

                      </p>
                    </div>
                  </div>     
              </div>

            </div>
            </div>


            

           
        </div>
        

      </div>

    </section>


    <section id="Why-Us?" class="whyus">
      <div class="d-flex justify-content-center container-1">
        <div class="col-12 text-center">
          <p class="text-1">Why Choose</p>
          <p class="text-2">BoldCup Consulting?</p>
        </div>  
      </div>

      <div class="d-flex justify-content-center container-2">
        <div class="col-4 card text-center">
          <img src="assets/img/svg/whyus2-1.svg" />
          <p class="text-1"  style="margin-bottom: 0px;margin-top:10px;">21st Century</p>
          <p class="text-1point5">Leadership Development</p>
          <p class="text-2">Our certified consultants possess industry-leading knowledge, supporting growth for every leader within your organization.</p>
        </div>  
        <div class="col-4 card text-center middle">
          <img src="assets/img/svg/whyus2-2.svg" />
          <p class="text-1">Customized Coaching</p>
          <p class="text-2">Moving game-changing information into company-wide transformation</p>
        </div>  
        <div class="col-4 card text-center">
          <img src="assets/img/svg/whyus2-3.svg" />
          <p class="text-1">Results</p>
          <p class="text-2">We're dedicated to delivering measurable success, boosting revenue, and prioritizing your business goals.</p>
        </div>  
        
      </div>

      <!-- <div class="d-flex justify-content-center container-3">
        <div class="box-1">
          <div class="d-flex justify-content-center">
            <div>
              <img src="assets/img/logos/BoldCup-Logo-round.svg">
            </div>
            <div class="text-container">
              <p class="text-1">Bold Cup Business Consulting</p>
              <span class="stars">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </span>
              <p class="text-2">Multiple 5-Star Reviews</p>
            </div>
          </div>
        </div>
        <div class="box-2">
            <div style="position: absolute;">
                <div class="d-flex justify-content-start animate" style="width: 10000px;">
                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>What an amazing company! Really helped turn my business around!</p>
                      </div>

                    </div>

                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>1</p>
                      </div>

                    </div>

                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>2</p>
                      </div>

                    </div>

                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>3</p>
                      </div>

                    </div>

                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>4</p>
                      </div>

                      

                    </div>

                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>What an amazing company! Really helped turn my business around!</p>
                      </div>

                    </div>

                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>1</p>
                      </div>

                    </div>

                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>2</p>
                      </div>

                    </div>

                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>3</p>
                      </div>

                    </div>

                    <div class="card align-items-start">
                      <div class="d-flex justify-content-center">
                        <div class="img-container">
                          <img src="assets/img/whyus/1.png"/>
                        </div>
                        <div class="text-container">
                          <p class="text-1">Greg McCombs</p>
                          <p class="text-2">2023-07-17</p>
                        </div>
                      </div>
                      <div class="star-container">
                        <span>
                          <i style="color: #f6bb06" class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div>
                        <p>4</p>
                      </div>

                      

                    </div>
                    

                    
                </div>
            </div>
        </div>
      </div> -->
      
    </section>


    <section id="Consultation" class="consult">
      <div class="row consult-background"> 
        <div style="background-image: url(assets/img/background/4.png);" class="consult-background-img"></div>
        <div class="overlay"></div>


        <div class="content">
          <div class="container-1">
              <p class="text-1">Try Us Out</p>
              <p class="text-2">Book a Consultation <span style="color: #B58744;">Today</span></p>
            </div>
            <div class="d-flex justify-content-center container-ovr">

              <div class="container-2">
              <div class="card" id="calendly-btn">
                <div class="img-container">
                  <img src="assets/img/Greg.png"/>

                </div>
                <p class="text-1">Greg McCombs</p>
                <p class="text-2">Owner / Lead Consultant</p>
                <button class="btn-3">Book Now</button>
              </div>
            </div>


              <div class="d-flex justify-content-center container-3">
              <form id="contact-form">
                  <div class="Contact-Card">
                      <div class="text-container">
                        <p class="text-1">Got Questions?</p>
                        <p class="text-2">Send us a Message</p>
                      </div>
                      <div class="row no-gutters d-flex justify-content-between mb-2 mt-4">
                          <div class="col-lg-6 col-sm-12" >
                              <input required class="form-control" id="name" placeholder="Name">
                          </div>
                          <div class="col-lg-6 col-sm-12 pho19">
                              <input required class="form-control" id="email" placeholder="Email">
                          </div>  
                      </div>
                      <textarea required class="form-control" id="message" placeholder="Inquiry"></textarea>
                      <div class="error-message"></div>
                      <div class="sent-message">Your message has been sent. Thank you!</div>
                      <button type="submit" class="btn-3" style="white-space: nowrap;"><div class="btn-text">Send &nbsp; <i class="fa-solid fa-arrow-right"></i></div> <i class="loading fa-2x fa-solid fa-circle-notch fa-spin"></i></button>
                  </div>     
                </form>
              </div>

            </div>
            

           
        </div>
     </div>

     

  </section>

 
</template>

<script>


  import "../../public/js/main.js"

  export default {
      name: 'HomeView',
      mounted(){
 

        // Function to check if an element is in the viewport
        function isInViewport(element) {
          var rect = element.getBoundingClientRect();
          return (
            rect.left >= 0 &&
            rect.top >= 0 &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          );
        }

        const ProcessAnimateElement1 = document.querySelector('.process .animate-div');
        const ProcessAnimateElement2 = document.querySelector('.process .animate-ovr');

        const HeroAnimateElement1 = document.querySelector('.hero .animate-div');
        const HeroAnimateElement2 = document.querySelector('.hero .animate-ovr');

        const triggerAnimateElement = document.querySelector('#trigger-process-animation');
        const InitialCardContainer = document.getElementById("expand-card");
        let initial = true;
        let loop = false

        function HeroAnimate(){
          HeroAnimateElement1.classList.add('animate');
            
            setTimeout(() => {
              HeroAnimateElement2.classList.add('animate-translate');
            }, 6850); // Delay for 4 seconds (cumulative delay)

            setTimeout(() => {
              HeroAnimateElement1.classList.remove('animate');
              HeroAnimateElement2.classList.remove('animate-translate');
            }, 7950); // Delay for 5 seconds (cumulative delay)

            
          }

          setInterval(HeroAnimate, 8000);


        function ProcessAnimate() {
          ProcessAnimateElement1.classList.add('animate');
            
            setTimeout(() => {
              ProcessAnimateElement2.classList.add('animate-translate');
            }, 4850); // Delay for 4 seconds (cumulative delay)

            setTimeout(() => {
              ProcessAnimateElement1.classList.remove('animate');
              ProcessAnimateElement2.classList.remove('animate-translate');
            }, 5950); // Delay for 5 seconds (cumulative delay)

            
          }


        // Function to handle scroll events
        function handleScroll() {
          if (isInViewport(triggerAnimateElement) && !loop) {
            loop = true;
            ProcessAnimate();
            setInterval(ProcessAnimate, 6000);
          }

          if (isInViewport(InitialCardContainer) && initial) {
            initial = false;
            setTimeout(() => {
              InitialCardContainer.classList.toggle('expanded');

            }, 800);

          }
        
          
        }

        // Add a scroll event listener to trigger the animation
        window.addEventListener('scroll', handleScroll);


        const btns = document.querySelectorAll(".to-consultation");
        const consultationSection = document.getElementById("Consultation");
        
        btns.forEach(btn => {
          btn.addEventListener("click", function () {
          if (consultationSection) {
            consultationSection.scrollIntoView({ behavior: "smooth" });
          }
        })
        })

        const btns2 = document.querySelectorAll(".to-about");
        const aboutSection = document.getElementById("About");
        
        btns2.forEach(btn => {
          btn.addEventListener("click", function () {
          if (aboutSection) {
            aboutSection.scrollIntoView({ behavior: "smooth" });
          }
        })
        })

        const calendlyBtn = document.getElementById("calendly-btn");
        
        calendlyBtn.addEventListener("click", function(){
          window.open("https://greg-boldcupconsulting.zohobookings.ca/#/30minconsult", '_blank');
        })



        // Select all the Read More links
        const readMoreLinks = document.querySelectorAll('.process .container-2 .card .read-more');

        readMoreLinks.forEach((readMoreLink) => {
          readMoreLink.addEventListener('click', function (e) {
            e.preventDefault(); // Prevent the default link behavior

            // Find the closest container-2 within the same card
            const cardContainer = this.closest('.container-2');

            // Toggle the 'expanded' class on the clicked container-2
            cardContainer.classList.toggle('expanded');

            // Find all the expanded container-2 elements and close them
            const expandedContainers = document.querySelectorAll('.process .container-2.expanded');
            expandedContainers.forEach((expandedContainer) => {
              if (expandedContainer !== cardContainer) {
                expandedContainer.classList.remove('expanded');
                const readMoreText = expandedContainer.querySelector('.read-more');
                readMoreText.style.display = 'block';

              }
            });

            // Show the "Read More" text for the clicked card
            const textContainer = cardContainer.querySelector('.text-container');
            const readMoreText2 = textContainer.querySelector('.read-more');
            readMoreText2.style.display = 'none'; // Hide "Read More" when expanded
          });

          const cardContainer = readMoreLink.closest('.container-2');

          cardContainer.addEventListener('mouseover', function () {
            
            if (getComputedStyle(readMoreLink).display != 'none') {
              readMoreLink.click();
            }
          });
        });

        
        


      }
      
  };

</script>

<style scoped>

.hero{
  display: flex;
  justify-content: center;
  overflow: hidden !important;
  z-index: 5;
}

.hero-background {
    position: relative;
    height: 900px;
    width: 100%; /* Full width of the parent container */


}

.hero .hero-background-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here (0.4 for 40% opacity) */
  z-index: 1; /* Ensure the overlay is above the background image */
  background-size: cover; /* Change this line */
  background-repeat: no-repeat;
  background-position: center bottom;
}

.hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here (0.4 for 40% opacity) */
  z-index: 1; /* Ensure the overlay is above the background image */
}

@media (min-width: 1900px) {

  .hero-background .overlay{
    left: 50% !important; /* Center the element horizontally */
    transform: translateX(-50%); /* Move it back by half of its width to center it */
    width: 1520px;
  }

  .process-background-img{
    left: 50% !important; /* Center the element horizontally */
    transform: translateX(-50%); /* Move it back by half of its width to center it */
    width: 1520px;
  }

  .process-background .overlay{
    left: 50% !important; /* Center the element horizontally */
    transform: translateX(-50%); /* Move it back by half of its width to center it */
    width: 1520px;
  }

  .consult-background-img{
    left: 50% !important; /* Center the element horizontally */
    transform: translateX(-50%); /* Move it back by half of its width to center it */
    width: 1520px;
  }

  .consult-background .overlay{
    left: 50% !important; /* Center the element horizontally */
    transform: translateX(-50%); /* Move it back by half of its width to center it */
    width: 1520px;
  }

  .hero-background-img{
    left: 50% !important; /* Center the element horizontally */
    transform: translateX(-50%); /* Move it back by half of its width to center it */
    width: 1520px;
  }

  .hero-background .overlay{
    left: 50% !important; /* Center the element horizontally */
    transform: translateX(-50%); /* Move it back by half of its width to center it */
    width: 1520px;
  }
  
}

.hero-background .overlay-shape {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the overlay is above the background image */
}

.hero-text{
  font-size: 40px;
  color:white;
  font-weight: 500;
  margin-bottom: 10px;
}

.hero-text-sm{
  font-size: 35px;
  color:white;
  font-weight: 500;
  margin-bottom: 10px;
}

.hero .animate-ovr {
  position: relative;
  width: 370px;
  overflow: hidden;
  margin-left:10px;
}

.hero .hero-text-2{
  transform: scale(1.2) translateX(25px);
}

.hero .animate-div p{
  color:#B58744;
  font-weight: 600;
  font-size: 35px;
}

.hero .animate-translate {
  opacity: 0 !important;
  transition: opacity 1s ease-in-out !important; /* Adjust the duration as needed */
}

.hero .animate-div{
  text-align: start;
  position: absolute;
}

.hero-subtext{
  font-size: 25px;
  color:white;
  opacity: 60%;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 50px;
}

.hero-background .space{
  margin-right: 5px;
  margin-lefT: 5px;
}


.accent{
  color: #B58744;
  font-weight: 600;
}


.about{
  margin-top: 100px;
  margin-bottom: 50px;
  line-height: 1;
}


.about .container-1{
  margin-bottom:50px;

}


.about .container-1 .text-1{
  font-size: 48px;
  font-weight: 500;
}

.about .container-1 .text-1-sm{
  font-size: 26px;
  font-weight: 500;

}

.about .container-1 .text-2{
  font-size: 25px;
  font-weight: 300;
  font-style: italic;
  padding-right:400px;

}

.about .container-1 .text-3{
  font-size: 22px;
  font-weight: 400;
  padding-left:40px;
  padding-bottom: 20px;

}


.about .container-2 .image-container {
  margin-top: 50px;
  position: relative;
  overflow: hidden;
  width: 500px;
  height: 650px;
  border-radius: 20px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.about .container-2 .image-container img {
  width: 1000px;
  left: -100px;
  position: absolute;
  object-fit: cover; /* Ensures the image covers the container and maintains its aspect ratio */
}

.about .container-3 .text-1{
  font-size: 25px;
  color:#B58744;
  font-weight: 400;
  margin-bottom:30px;
}

.about .container-3 .text-2{
  font-size: 17px;
  opacity: 80%;
  margin-bottom:50px;
  line-height: 1.3;
}

.about .container-3 button{
  font-size: 18px !important;
  border-right: 35px solid transparent;
  border-left: 35px solid transparent;

}


.process{
  display: flex;
  justify-content: center;
  overflow: hidden !important;
  z-index: 5;
}

.process::before {
  content: "";
  display: block;
  height: 200px; /* Adjust this to your desired offset value */
  margin-top: -200px; /* Negative margin equal to the offset value */
  visibility: hidden;
}

.process-background {
    position: relative;
    height: 1400px;
    width: 100%; /* Full width of the parent container */


}

.process .process-background-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here (0.4 for 40% opacity) */
  z-index: 1; /* Ensure the overlay is above the background image */
  background-size: cover; /* Change this line */
  background-repeat: no-repeat;
  background-position: left bottom;
}

.process .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here (0.4 for 40% opacity) */
  z-index: 1; /* Ensure the overlay is above the background image */
}

.process .content{
  z-index: 2;
  margin-top:100px;
}

.process .container-1{
  text-align: center;
  line-height: 1.4;
  padding: 20px;
  padding-bottom: 40px;
  border-radius: 20px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  width: fit-content;
  background-color:white;
}

.process .container-1 .text-1{
    font-size: 40px;
    font-weight: 600;
    color: black;
    margin-bottom:0;
}

.process .container-1 .text-2{
    font-size: 25px;
    font-weight: 500;
    color: black;
    margin-bottom:0;
    opacity: 80%;
}

.process .animate-ovr {
  position: relative;
  width: 230px;
  overflow: hidden;
  margin-left:10px;
}

.process .animate-ovr p {
  font-size: 25px;
    font-weight: 500;
    color: #B58744;
    margin-bottom:0;
    opacity: 80%;
}

.process .animate-div {
  position: absolute;
  text-align: start;
}



.process .container-2 .card{
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  width: fit-content;
  margin:10px;
  transition: transform 1s ease-in-out; /* Add transitions for smooth animation */
  overflow: hidden

}



.process .container-2.expanded .card .text-container {
  width: 300px;
  height: 250px;
  transition: all 1s ease-in-out;
}

.process .container-2.expanded .card .read-more {
  display: none;
}
.process .container-2 .card .read-more {
  text-decoration:underline !important;
}

.process .container-2 .card span{
  font-weight: 800;

}

.process .container-2 .card .text-container {
  margin-left: 30px;
  width: 220px;
  height: 100px;
  transition: all 1s ease-in-out; /* Specify the transition duration in seconds (1s) */
}





.process .container-2 .card .img-container {
  position: relative;
  width: 250px;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  background-size: cover; /* Change this line */
  background-position: center top;
  background-repeat: no-repeat;


}

.process .container-2 .card img {
  position: absolute;
}


.process .container-2 .card .text-1 {
    font-size: 30px;
    font-weight: 600;
    color: black;
    margin-bottom:0;
}

.process .container-2 .card .text-2 {
    font-size: 16px;
    font-weight: 500;
    opacity: 80%;
    margin-top:10px;
}

.whyus{
  margin-top:100px;
}

.whyus::before {
  content: "";
  display: block;
  height: 200px; /* Adjust this to your desired offset value */
  margin-top: -200px; /* Negative margin equal to the offset value */
  visibility: hidden;
}

.whyus .container-1{
  margin-bottom:60px;
  line-height: 1.1;

}


.whyus .container-1 .text-1{
  font-size: 35px;
  font-weight: 500;
  color:#B58744;
  margin-bottom: 0;
}

.whyus .container-1 .text-2{
  font-size: 48px;
  font-weight: 600;
}


.whyus .container-2 .card {
  width: 300px;
  align-items: center;
  padding: 10px;
  padding-top:30px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  margin:30px;
  transition: transform 0.3s ease-in-out;
}

.whyus .container-2 .middle {
  transform: translateY(-20px) scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.whyus .container-2 .middle:hover {
  transform: translateY(-20px) scale(1.1) !important;  
  transition: transform 0.3s ease-in-out;
}

.whyus .container-2 .card:hover{
  transform: scale(1.05);
}

.whyus .container-2 .card img{
  width: 100px;
}

.whyus .container-2 .card .text-1{
  font-size: 28px;
  font-weight: 500;
  margin-top:25px;
  margin-bottom: 15px;
}


.whyus .container-2 .card .text-1point5{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}


.whyus .container-2 .card .text-2{
  font-size: 18px;
  font-weight: 400;
}


.whyus .container-2{
  margin-bottom:60px;
  line-height: 1.1;

}

.whyus .container-3 {
  margin-bottom:60px;
  line-height: 1.1;

}


.whyus .container-3 .box-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whyus .container-3 .box-1 img {
  height:80px;
}

.whyus .container-3 .box-1 .text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 15px;
}

.whyus .container-3 .box-1 .text-1 {
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 500;
  padding-top: 1000px;
}



.whyus .container-3 .box-1 .stars {
  margin-bottom: 5px;

}

.whyus .container-3 .box-2 {
  position: relative;
  overflow: hidden;
  width: 1000px;
  height: 200px;
  margin-left: 100px;
}

.whyus .container-3 .box-2 .animate{
  width: 10000px;
  animation: scrollCards 35s linear infinite;
}

@keyframes scrollCards {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1750px); /* Move the cards to the left by one card width */
  }
}

.whyus .container-3 .box-2 .card {
  align-items: center;
  padding: 10px;
  padding-top:30px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  margin:0px;
  transition: transform 0.3s ease-in-out;
  width: 250px;
  margin-right:100px;
}


.whyus .container-3 .box-2 .card img {
  height: 50px;
  border-radius: 1000px;
}

.whyus .container-3 .box-2 .card .text-1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}
.whyus .container-3 .box-2 .card .text-1 {
  margin-bottom: 0;
}

.whyus .container-3 .box-2 .card .text-container {
  padding-left:10px;
  padding-top: 5px;
}

.whyus .container-3 .box-2 .card .star-container {
  margin-bottom:10px;
}
.whyus span i {
  color: #f6bb06;
}


.consult{
  display: flex;
  justify-content: center;
  overflow: hidden !important;
  z-index: 5;
  margin-bottom: 100px;
}


.consult-background {
    position: relative;
    height: 1000px;
    width: 100%; /* Full width of the parent container */


}

.consult .consult-background-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here (0.4 for 40% opacity) */
  z-index: 1; /* Ensure the overlay is above the background image */
  background-size: cover; /* Change this line */
  background-repeat: no-repeat;
  background-position: left bottom;
}

.consult .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here (0.4 for 40% opacity) */
  z-index: 1; /* Ensure the overlay is above the background image */
}


.consult .content {
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.consult .content .container-1 {
  text-align: center;
  line-height:1.1;
  margin-top:100px;
  margin-bottom: 100px;
}

.consult .content .container-1 .text-1{
  font-size: 35px;
  font-weight: 500;
  color:#B58744;
  margin-bottom: 0;
}

.consult .content .container-1 .text-2{
  font-size: 48px;
  font-weight: 600;
  color: white;
}

.consult .card {
 padding: 0px;
 background-color: white;
 width:300px;
 line-height: 1.1;
 border-radius: 20px;
 overflow: hidden;
 border: none;
 transition: transform 0.3s ease-in-out;
 cursor: pointer;
 transform: scale(1.1);
}

.consult .container-2 {
 margin-top: 20px;
 margin-left: 100px;
}
.consult .card:hover {
 transform: scale(1.15);
}


.consult .card .img-container {
  position: relative;
 height: 350px;
 border-radius: 20px 20px 40px 40px;
 box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
 overflow: hidden;
}

.consult .card img {
 width:300px;
 position: absolute;
}

.consult .card .text-1 {
 font-size: 25px;
 font-weight: 600;
 margin-bottom: 0px;
 margin-top: 20px;
 padding-left: 10px;
}

.consult .card .text-2 {
 font-size: 16px;
 opacity: 80%;
 font-weight: 500;
 margin-top: 10px;
 padding-left: 10px;
}

.consult .card button {
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

}

.consult .container-3{
  line-height: 1.1;
}


.consult .container-3 .text-1{
  font-size: 25px;
  font-weight: 500;
  color:#B58744;
  margin-bottom: 0;
}

.consult .container-3 .text-2{
  font-size: 40px;
  font-weight: 600;
  color: black;
}

.Contact-Card{
  margin:100px;
  margin-top:0px;
  padding:20px;
  text-align: center;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: white;

}

.Contact-Card button{
    margin-top:25px;
    border-right:200px solid transparent;
    border-left:200px  solid transparent;
    margin-bottom: 10px;
}

input
{
  border: none;
  font-size: 20px !important;
  height: 50px;
  transition: all 0.3s ease-in;
  border: 0px solid #ced6e0 !important;
  transition: all 0.2s ease-in-out ;
  background-color: #f4f4f4 !important;
  border-radius: 2px !important;
  
}

textarea{
  resize: none !important;
  border: none;
  font-size: 20px !important;
  min-height: 250px !important;
  transition: all 0.3s ease-in;
  border: 0px solid #ced6e0 !important;
  transition: all 0.2s ease-in-out ;
  background-color: #f4f4f4 !important;
  border-radius: 2px !important;
}


input:focus  {
  border: 2px solid !important;
  border-color: black !important;
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35) !important;
}

textarea:focus  {
  border: 2px solid !important;
  border-color: black !important;
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35) !important;
}





.loading{
  display: none;
}

.error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: cetner;
    padding: 15px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 18px;
    margin-top:20px;
    border-radius: 10px;

  }

  .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 18px;
    margin-top:20px;
    border-radius: 10px;
  }


    
/* If screen is large */

@media (min-width: 992px) {



  .hero .temp-container{
    justify-content:center;
    margin-top:200px;

  }
  .hero .hero-logo-container{
    margin-right: 100px;
    margin-bottom: 50px;
    margin-top:100px;
  }

  .hero .animate {
  animation: translateUp 6s ease-in-out 0.3s 1 forwards alternate;

}

@keyframes translateUp {
  0% {
    transform: translateY(0px); /* Start with no translation */
  }
  18% {
    transform: translateY(0px); /* Translate up by 200px at 25% of the animation */
  }
  30% {
    transform: translateY(-50px); /* Back to no translation at 50% */
  }
  32% {
    transform: translateY(-50px); /* Back to no translation at 50% */
  }
  50% {
    transform: translateY(-100px); /* Translate up again at 75% */
  }
  52% {
    transform: translateY(-100px); /* Translate up again at 75% */
  }
  68% {
    transform: translateY(-150px); /* End with no translation at 100% */
  }
  70% {
    transform: translateY(-150px); /* End with no translation at 100% */
  }
  100% {
    transform: translateY(-204px); /* End with no translation at 100% */
  }
}

  .hero .hero-logo-container img{
    width: 350px;
  }

  .about .text-container{
    width:600px;
    margin-top:140px;
  }

  
.process .container-2 .card:hover{
  transform: scale(1.05);
}

.process .animate {
  animation: translateUpProcess 3s ease-in-out 0.3s forwards alternate;
}

.process .animate-translate {
  opacity: 0 !important;
  transition: opacity 1s ease-in-out !important; /* Adjust the duration as needed */
}

@keyframes translateUpProcess {
  0% {
    transform: translateY(0px); /* Start with no translation */
  }
  30% {
    transform: translateY(0px); /* Translate up by 200px at 25% of the animation */
  }
  30% {
    transform: translateY(-34px); /* Back to no translation at 50% */
  }
  33% {
    transform: translateY(-34px); /* Back to no translation at 50% */
  }
  63% {
    transform: translateY(-70px); /* Translate up again at 75% */
  }
  66% {
    transform: translateY(-70px); /* Translate up again at 75% */
  }
  90% {
    transform: translateY(-105px); /* End with no translation at 100% */
  }
  100% {
    transform: translateY(-105px); /* End with no translation at 100% */
  }
}


}

/* If screen is small */
@media (max-width: 992px) {



  .hero .hero-background {
    height: 500px !important;
  }

  .hero .temp-container{
    justify-content:start;
    margin-top:250px;
  }
  .hero .hero-logo-container{
    margin-bottom:50px;
    margin-top:200px;
  }
  .hero .hero-logo-container img{
    width: 300px;
  }
  

  .hero .hero-text{
    font-size:22px
  } 
  
  .hero .animate-div p{
    font-size:21px;
  }
  .hero .hero-text-sm{
    font-size:21px;
  }

  .hero .animate-ovr{
    width:200px
  }

  .hero .hero-subtext{
    font-size:18px;
  }



  .hero .animate {
  animation: translateUp 6s ease-in-out 0.3s 1 forwards alternate;
  }

    @keyframes translateUp {
      0% {
        transform: translateY(0px); /* Start with no translation */
      }
      18% {
        transform: translateY(0px); /* Translate up by 200px at 25% of the animation */
      }
      30% {
        transform: translateY(-35px); /* Back to no translation at 50% */
      }
      32% {
        transform: translateY(-35px); /* Back to no translation at 50% */
      }
      50% {
        transform: translateY(-71px); /* Translate up again at 75% */
      }
      52% {
        transform: translateY(-71px); /* Translate up again at 75% */
      }
      68% {
        transform: translateY(-110px); /* End with no translation at 100% */
      }
      70% {
        transform: translateY(-110px); /* End with no translation at 100% */
      }
      100% {
        transform: translateY(-148px); /* End with no translation at 100% */
      }
    }


  .about .container-1 .text-1{
  font-size: 25px;
  font-weight: 500;

}


.about .container-1 .text-1-sm{
      font-size: 20px;
      font-weight: 500;

    }

.about .container-1 .text-2{
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  padding-right:250px;

}

.about .container-1 .text-3{
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 20px;
  padding-left:50px
}

  .about .image-container-ovr{
    display:none !important;
  }

  .about .text-container{
    padding:16px
  }

  .about .text-container .text-2{
    font-size: 16px !important;
  }

  .process .container-1 .text-1{
    font-size: 35px;
  }


  .process .container-1 .text-2{
    font-size: 16px;
    white-space: nowrap;
  }

  .process .container-1 .animate-div p{
    font-size: 16px;
  }


  .process .container-1 .animate-ovr {
    width: 140px;
    overflow:hidden;
    margin-left:5px
  }

  .process .container-2 .card{
    transform: scale(0.6) !important;
  }

  .process .container-2 .card:hover{
    transform: scale(0.65) !important;
  }

  .process .container-2 {
    margin-top: -80px !important;
  }

  .process .process-background{
    height: 950px !important;
  }

  .process .animate {
  animation: translateUpProcess 3s ease-in-out 0.3s forwards alternate;
}

@keyframes translateUpProcess {
  0% {
    transform: translateY(0px); /* Start with no translation */
  }
  30% {
    transform: translateY(0px); /* Translate up by 200px at 25% of the animation */
  }
  30% {
    transform: translateY(-23px); /* Back to no translation at 50% */
  }
  33% {
    transform: translateY(-23px); /* Back to no translation at 50% */
  }
  63% {
    transform: translateY(-46px); /* Translate up again at 75% */
  }
  66% {
    transform: translateY(-46px); /* Translate up again at 75% */
  }
  100% {
    transform: translateY(-67px); /* End with no translation at 100% */
  }
} 

  .whyus .container-1 {
    margin-bottom: 10px;
  }

  .whyus .container-1 .text-1{
    font-size: 25px;
  }

  .whyus .container-1 .text-2{
    font-size: 30px;
  }

  .whyus .container-2{
    flex-direction: column;
    align-items:center;
  }

  .consult .container-1 .text-1{
    font-size: 22px !important;
  }

  .consult .content .container-1 .text-2{
    font-size: 25px !important;

  }

  .consult .content .container-ovr{
    flex-direction:column;
    align-items:center;
  }

  
  .consult .content .container-2{
    margin: 0px;
    transform: scale(0.9) translateY(-100px)
  }

  .consult .content .container-3{
    margin: 0px;
    transform: scale(0.7) translateY(-160px)
  }

  .consult .consult-background{
    height: 1200px;
  }



}


</style>
