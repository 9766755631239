<template>
  <div id="nav-container" class="fixed-top nav-container">
    <nav id="nav" class="navbar navbar-expand-lg navbar-light" style="z-index: 5;">
          

                <div class="logo-container-2" id="nav-logo-container-2">
                  <div style="display: absolute;transform:translateY(-60px)">
                    <img class="img1" id="img1-2" style="transform: translateY(55px)" src="assets/img/logos/BoldCup-Logo-Black.svg"  />
                    <img class="img2"  id="img2-2" style="transform: translateY(-59px)"  src="assets/img/logos/BoldCup-Logo-White.svg"  />
                  </div>
                  
                </div>

  
            <button type="button" class="navbar-toggle collapsed d-lg-none" data-toggle="collapse" data-target="#navbar"
              aria-expanded="false" aria-controls="navbar">
              <div class="bar bar1"></div>
              <div class="bar bar2"></div>
              <div class="bar bar3"></div>
            </button>

  
            
            
            <div class="collapse navbar-collapse d-flex justify-content-center" style="flex-grow: 0 !important;" id="navbar"> 
              <ul class="navbar-nav mx-auto">
                <li class="nav-item item">
                  <a class="nav-link active" href="#About" style="margin-right: 50px;">About</a>
                </li>
                <li class="nav-item item">
                  <a class="nav-link" href="#Process" style="margin-right: 10px;">Process</a>
                </li>
                <div class="logo-container" id="nav-logo-container">
                  <div style="display: absolute">
                    <img class="img1" id="img1" style="transform: translateY(55px)" src="assets/img/logos/BoldCup-Logo-Black.svg"  />
                    <img class="img2"  id="img2" style="transform: translateY(-59px)"  src="assets/img/logos/BoldCup-Logo-White.svg"  />
                  </div>
                  
                </div>
                <li class="nav-item item">
                  <a id="move-left" class="nav-link lefty" href="#Why-Us?">Why Us?</a>
                </li>
                <li class="nav-item item">
                  <a class="nav-link lefty" href="#Consultation" >Contact</a>
                </li>
              </ul>
            </div>
          
  
            
  
          </nav>
  </div>
  </template>
  
  <script>
  export default {
    mounted(){
      const navLinks = document.querySelectorAll('.navbar-nav li a');
      function toggleSelected() {
      navLinks.forEach(link => {
          if (link.classList.contains('active')) {
          link.classList.remove('active');
          }
      });
      
      this.classList.add('active');
      }

      navLinks.forEach(link => {
          link.addEventListener('click', toggleSelected);
      });


      var navbarToggler = document.querySelector('.navbar-toggle');
      var navbarCollapse = document.querySelector('.navbar-collapse');

      // Get the current screen width
      let screenWidth = window.innerWidth;

      function handleScreenWidthChange() {
          // Get the current screen width
          screenWidth = window.innerWidth;

          // Check the screen width and perform actions accordingly
          if (screenWidth <= 992) {
        
            navbarCollapse.style.setProperty('display', 'none', 'important');
          }
          else {
            // For screens 768 pixels wide or larger
            navbarCollapse.style.setProperty('display', 'block', 'important');

            // Add your code here for larger screens
          }
        }
                // Initial call to handleScreenWidthChange
        handleScreenWidthChange();

        window.addEventListener("resize", handleScreenWidthChange);

        var bars = document.querySelectorAll('.bar');


      navbarToggler.addEventListener('click', function() {
          if (navbarCollapse.classList.contains('show')) {
  
              navbarCollapse.classList.remove('show');
              navbarToggler.classList.remove('change');


              setTimeout(function() {
                  navbarCollapse.style.opacity = '0';
                  setTimeout(function() {
                    navbarCollapse.style.setProperty('display', 'none', 'important');

                  }, 300); // wait for the opacity transition to finish

              }, 0); // wait for the opacity transition to finish

              


          } else {
              navbarCollapse.classList.add('show');
              navbarToggler.classList.add('change');
              navbarCollapse.style.display = 'block';

              setTimeout(function() {
                  navbarCollapse.style.opacity = '1';
              }, 0);

              bars.forEach(function(bar) {
                bar.style.setProperty('background-color', "#000")
              });
              
          }

          
      });

      var navbarNavLinks = document.querySelectorAll('.navbar-nav>li>a');
      navbarNavLinks.forEach(function(navbarNavLink) {
        navbarNavLink.addEventListener('click', function() {
          if (screenWidth <= 992) {
        
            navbarCollapse.classList.remove('show');
            navbarToggler.classList.remove('change');
            setTimeout(function() {
              navbarCollapse.style.opacity = '0';
              setTimeout(function() {
                navbarCollapse.style.setProperty('display', 'none', 'important');
              }, 300); // wait for the opacity transition to finish
            }, 0); // wait for the opacity transition to finish
        }

        });
      });  

      const element = document.getElementById("nav-container");

      const img1 = document.getElementById("img1");
      const img2 = document.getElementById("img2");


      const img12 = document.getElementById("img1-2");
      const img22 = document.getElementById("img2-2");

    
      function isPageAtTop() {
          return window.scrollY <= 200;
      }

      function triggerScrollCheck() {


        
          if (isPageAtTop()) {
              
              img1.style.setProperty('opacity', '0');
              img2.style.setProperty('opacity', '1');
              img12.style.setProperty('opacity', '0');
              img22.style.setProperty('opacity', '1');
              element.classList.remove('solid-nav');

              bars.forEach(function(bar) {
                bar.style.setProperty('background-color', "#fff")
              });


          } else {
              element.classList.add('solid-nav');
              img1.style.setProperty('opacity', '1');
              img2.style.setProperty('opacity', '0');
              img12.style.setProperty('opacity', '1');
              img22.style.setProperty('opacity', '0');
              bars.forEach(function(bar) {
                bar.style.setProperty('background-color', "#000")
              });


              
          }
      }

      // Attach the scroll event listener to update the class
      window.addEventListener('scroll', triggerScrollCheck);

      // Initial check to apply the class based on the initial scroll position
      triggerScrollCheck();


          


     

    }
  }
  </script>
  
    <style>

  .nav-container {
    z-index: 5;
    margin: 0; /* Set margin to 0 */
    background-color: transparent; /* Set background-color to transparent */
    transition: all 0.5s ease-in-out;
  }
  


  .logo-container,
  .logo-container-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    width: 200px;
    height: 100px;
    transition: width 0.5s ease-in-out;
    display: relative;
    overflow: hidden

  }

  
  .logo-container img,
  .logo-container-2 img {
    width: 150px;
    opacity: 1;
    margin-left: 50px;
    transition: opacity 0.3s ease-in-out;
    transition: width 0.1s ease-in-out;
    width: 150px;
  }

  .logo-container .img1,
  .logo-container-2 .img1 {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

  }
  .logo-container .img2,
  .logo-container-2 .img-2 {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

  }
  


  .solid-nav{
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
    background-color: white;
  }
  
  nav{
    position: fixed;
    max-width: 1440px;
    
    margin-left: auto; /* Center horizontally by setting left margin to auto */
    margin-right: auto; /* Center horizontally by setting right margin to auto */
}


.navbar-nav li{
    margin-top: 20px;
}



.navbar-nav li.item a.active{
    position: relative;
    border-bottom: 3px solid #fff;
    padding-bottom: 6px;
    transition: all 2s ease-out;
}

.solid-nav .navbar-nav li.item a.active{
    border-bottom: 3px solid #000000;

}


.navbar-nav li.item a.active:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.3s ease-in-out;
  }
  .navbar-nav li.item a.active:hover:before,
  .navbar-nav li.item a.active:focus:before {
    transform: scaleX(1);
  }


  @media (max-width:992px){

    
  .navbar {
    justify-content:between !important;;
  }

    .navbar-nav li a{
      color: black !important;
    }
    .navbar-nav .logo-container{
      display:none;
    }
    

  }

  @media (min-width:992px){

    .navbar {
    justify-content:center !important;;
  }

    .navbar-nav li a{
      color: white !important;
    }
    
    .navbar-nav .lefty{
      margin-left:50px;
    }


  }


.navbar-nav li a {
    font-size: 20px;
    font-weight: 500 !important;
    transition: all 0.3s ease-in-out !important;

  }

  .solid-nav .navbar-nav li a {
    color: black !important;
  }

  .navbar-nav li a:hover {
    color:#000000;

  }

  .navbar {
    height: 120px;
  }

  .navbar-nav {
    display: flex;
    justify-content: none;
    align-items: none;
  }
  
  .navbar-rounded {
    clip-path: ellipse(50% 50% at 50% 0%);
  }
  .navbar-nav.ms-auto{
    display:flex;
  }



  @media (min-width: 992px) {
    .navbar-collapse {
        opacity: 1 !important;
      }

      nav{
      flex-wrap: wrap !important;
    }

    .logo-container-2{
      display: none;
    }
  }


  @media (max-width: 992px) {

    .navbar-nav li a {
    font-size: 25px !important;


  }

  .logo-container-2{
      display: block;
    }

    nav{
      padding-right:20px !important;
      padding-left:20px !important;
    }

    .navbar-collapse {
        position:fixed;
        z-index: 2;
        overflow-y: auto;
        background-color: white; 
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        flex-grow: 0 !important;
        top: 0; /* Make sure it starts at the top of the viewport */
        right: 0; /* You can adjust this for positioning */
        bottom: 0; /* You can adjust this for positioning */
        left: 0; /* You can adjust this for positioning */
      }

    .navbar-nav {
        margin-top:100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .navbar-nav.ms-auto{
        display: none;
      }

      .navbar-nav li a {
        font-size: 40px;
        font-weight: 600 !important;
        margin-right: 0px !important;
        margin-top:60px;

      }

      .navbar-toggle{
    border:none;
    z-index: 5;
    background-color: transparent;
  }


  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
  }

  
  .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }



  }

  </style>
  