<template>
  <footer class="footer">
    <div class="container">
      <div class="row no-gutters">


        <div class="col-lg-4 col-md-0">

          <img src="assets/img/logos/BoldCup-Logo-round.svg" style="width: 100px;"/>

          </div>

      <div class="col-lg-4 col-md-6">
        <div class="">
          <p style="font-weight: 600;"><a>Home</a></p>
          <p><a href="#About">About Us</a></p>
          <p><a href="#Process">Process</a></p>
          <p><a href="#Why-Us?">Why Us?</a></p>
        </div>
        

      </div>
      <div class="col-lg-4 col-md-6">
        <div class="">
          <p style="font-weight: 600;">Contact</p>
          <p>greg@boldcupconsulting.com</p>
          <p>Calgary, AB Canada</p>
        </div>
        
      </div>

    </div>
    <div class="text-start">
      <p style="opacity: 80%;font-weight: 500;font-size: 18px;">&copy; 2023 Bold Cup Consulting</p>

    </div>
    </div>
    
  </footer>
</template>

<script>
export default {
  // Component logic, if needed
}
</script>

<style scoped>
/* Component-specific styles for the footer */
.footer {
  display: flex;
  justify-content: center;
}

.footer p{
  font-size: 18px;
  font-weight: 400;
  opacity: 90%;
}

@media (max-width: 992px) {
  .footer img{
    display: none;
  }

  .footer .row{
    display: flex;
    justify-content: center;
  }

  .footer .col-md-6{
    width: 200px;
  }

  .footer .text-start{
    text-align: center !important;
    margin-top: 20px;
  }
}

</style>
